<template>
    <div class="flex flex-col items-center">
        <h2 v-text="$t('error.headline', { code: error?.statusCode })" />
        <p v-text="errorMessage" />
        <form-button
            element="link"
            type="primary"
            class="mt-8"
            @click="handleError"
        >
            {{ $t("error.button") }}
        </form-button>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

const { t } = useI18n();

const props = withDefaults(
    defineProps<{
        error: NuxtError;
    }>(),
    {
        error: null,
    },
);

definePageMeta({
    title: "error.title",
});

const handleError = () => clearError({ redirect: "/" });

const errorMessage = computed(() => {
    if (props.error?.statusCode === 401) {
        return t("error.401");
    } else if (props.error?.statusCode === 404) {
        return t("error.404");
    } else if (props.error?.statusCode === 500) {
        return t("error.500");
    }

    return t("error.generic");
});
</script>
